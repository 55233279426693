.container {
	padding: clamp(30px, 3.13vw, 60px) 0 0;
}

.title {
	font-style: normal;
	font-weight: 700;
	font-size: clamp(24px, 2.19vw, 42px);
	margin-top: 0;
	margin-bottom: 25px;

	&.video span {
		color: #6371de;
	}

	&.image span {
		color: #b472dd;
	}
}

@media screen and (max-width: 1100px) {
	.container {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.title {
		text-align: center;
	}
}

@media screen and (max-width: 680px) {
	.container {
		padding-top: 15px;
	}

	.title {
		margin: 0;
		font-size: 36px;
	}
}
