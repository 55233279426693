@import "../GeneralStyle.scss";

.features_items_wrapper {
	margin-bottom: clamp(62px, 4.68vw, 88px);
}

.scroll {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	gap: clamp(12px, 1.04vw, 20px) clamp(18px, 1.45vw, 26px);
}

.features_item {
	width: clamp(200px, 13.96vw, 268px);
	padding: 16px 10px;
	box-sizing: border-box;
	background: #f9f8fe;
	box-shadow: 4px 4px 24px rgba(99, 113, 222, 0.3);
	border-radius: 16px;
	text-align: center;
	text-decoration: none;
	color: #1c1f1e;
	min-height: 272px;

	&.big {
		width: clamp(268px, 16.93vw, 325px);
		.item_title {
			font-size: clamp(22px, 1.46vw, 28px);
		}

		.item_description {
			font-size: clamp(18px, 1.15vw, 22px);
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
		position: relative;
	}

	.soon {
		background: #6371de;
		color: white;
		position: absolute;
		top: -12px;
		right: -12px;
		height: 50px;
		width: 50px;
		box-sizing: border-box;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 2px 2px 4px rgba(180, 114, 221, 0.15);
		-webkit-transform: rotate(25deg);
		transform: rotate(25deg);
	}
}

.item_img {
	margin: auto;
	max-width: 100%;
}

.item_title {
	font-size: clamp(16px, 1.04vw, 20px);
	line-height: 120%;
	margin: 13px 0 0;
}

.item_description {
	margin: 17px 2px 0;
	font-size: clamp(13px, 0.83vw, 16px);
	line-height: 20px;
	text-wrap: balance;
}

@media screen and (max-width: 1024px) {
	.scroll {
		justify-content: center;
	}
}

@media screen and (max-width: 680px) {
	.features_items_wrapper {
		margin-bottom: 30px;
		overflow-x: scroll;
		overflow-y: visible;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		&::-webkit-scrollbar-thumb {
			background: rgba(90, 90, 90);
		}

		&::-webkit-scrollbar-track {
			background: rgba(0, 0, 0, 0.2);
		}
	}

	.scroll {
		padding: 28px;
		justify-content: flex-start;
		flex-direction: row;
		flex-wrap: nowrap;
		width: fit-content;
		overflow-y: visible;
		gap: 26px;
	}
}
